import React from "react";

export default function ChangelogPosition({ update }) {
  const { title, description } = update;
  return (
    <>
      <article className="log__position">
        <h3>{title}</h3>
        <ul>
          {description.map((item, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: item}}></li>
          ))}
        </ul>
      </article>
    </>
  );
}
