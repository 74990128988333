import React, { useState, useEffect } from "react";

import { navigate } from "gatsby";

export default function Jump() {
  const ref = React.createRef();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    ref.current.focus();

    document.onkeydown = function (e) {
      switch (e.key) {
        case "Escape":
          closeModal();
          break;
        case "Enter":
          hop();
          break;
      }
    };
  }, [isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const hop = () => {
    const number = parseInt(ref.current.value);
    navigate(`/photo/${number}`);
  };

  return (
    <>
      <button className="button  button--inverted jump" onClick={showModal}>
        Skocz do wybranego zdjęcia
      </button>

      <div className={`modal ${isModalVisible ? "modal--visible" : null}`}>
        <div className="modal__content">
          <button
            className="modal__close"
            onClick={closeModal}
            aria-label="Close the layer"
          ></button>

          <div className="modal__hop">
            <input
              ref={ref}
              placeholder="0000"
              aria-label="Podaj numer zdjęcia"
            />
            <button onClick={hop} className="button button--centered  ">
              Hop! Hop! Hop!
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
