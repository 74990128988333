import * as React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Changelog from "../components/changelog";
import Jump from "../components/jump"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="" />
      <main className="homepage">
        <section className="hero">
          <div>
            <h1 className="hero__title">KORSAN:2000</h1>
            <p>
              7 sierpnia 2015 roku wrzuciłem na{" "}
              <a href="https://tookapic.com/maciejkorsan">tookapic.com</a> swoje
              pierwsze zdjęcie. Nie wiedziałem wtedy, że moje wyzwanie: jedno
              zdjęcie dziennie, codziennie, potrwa dłużej niż zakładane 365 dni.
              Po wrzuceniu zdjęcia numer 365 nie potrafiłem się zatrzymać.
              Kolejne lata (!) mijały, a mój projekt wciąż trwa.
            </p>
            <p>
              Strona, którą właśnie odwiedzasz, to wystawa podsumowująca 2000
              dni mojej fotograficznej wędrówki. Znajdziesz tu nie tylko
              zdjęcia, z których jestem dumny. Jest tutaj też masa codzienności,
              zdjęć robionych na ostatnią chwilę przed północą. Chciałbym Ci o
              nich wszystkich opowiedzieć, tak jak robiłem to w trakcie dwóch
              poprzednich wystaw, które zorganizowałem. Na stronach ze
              zdjęciami, znajdziesz specjalny przycisk -{" "}
              <em>Opowiedz mi Korsi!</em>. Naciśnij go, a nagram opis związany z
              danym kadrem.
            </p>
            <p>
              Żałuję, że nie możemy w tym roku spotkać się osobiście, ale myślę,
              że istnieją spore szanse, że projekt wytrwa do zdjęcia numer 3000.
            </p>
            <p>
              Życzę miłego odbioru, <br />
              Maciek Korsan
            </p>
          </div>

          <img className="korsi" src="/opowiedz.png" alt="Opowiedz mi Korsi" />
        </section>
        <section className="what">
          <h2>Ok, co mogę tutaj zrobić 🤔</h2>
          <div className="what__links">
            <Link className="what__link what__link--selection" to="/fav/1">
              <span>Przejrzyj wybrane przeze mnie zdjęcia&nbsp;🙋‍♂️</span>
            </Link>
            <Link className="what__link what__link--podcast" to="/podcast">
            <span>Przesłuchaj wszystkie nagrania&nbsp;🎤</span>
            </Link>
            <Link className="what__link what__link--all" to="/photo/1">
            <span>Przejrzyj całą kolekcję&nbsp;📷</span>
            </Link>
          </div>
          <Jump/>

          <Changelog/>
        </section>
        <footer>
          Dzięki za odwiedziny, po więcej moich zdjęć zapraszam na <a href="https://instagram.com/maciejkorsan">Instagrama</a>😎
        </footer>
      </main>
    </Layout>
  );
};
export default IndexPage;
