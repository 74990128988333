import React from "react";
import ChangelogPosition from './changelog-position'

export default function Changelog() {


    const updates = [

        {
            title: "31.01.2021 20:30",
            description: [
                `+13 nowych nagrań z zaproponowanych przez odwiedzających 🎙`,
                `Lista zdjęć z możliwością sortowania po datach zrobienia zdjęcia i dodania nagrań w zakładce <a href="/podcast">Przesłuchaj wszystkie nagrania</a> 🎉 `,
                `Możliwość swipe'owania zdjęć w zakładkach z ulubionymi / wszystkimi zdjęciami 📲`,
            ]
        },

        {
            title: "30.01.2021 19:00",
            description: [
                `Kompletne nagrania do zdjęć wybranych przeze mnie 🎉`,
            ]
        },

        {
            title: "30.01.2021 17:15",
            description: [
                `Funkcja skocz do zdjęcia`,
                'Poprawki stylów CSS',
                'Usunięcie nieużywanych fragmentów kodu',
            ]
        },

        {
            title: "30.01.2021 10:00",
            description: [
                `25 nowych opisów do zdjęć wybranych przeze mnie`,
                'Poprawki językowe',
                'Drobne poprawki w kodzie',
            ]
        },
        {
            title: "29.01.2021 ",
            description: [
                'Projekt ujrzał światło dzienne',
                'Pierwsze nagrania',
                'Pierwsza wersja 🚀',
                `<a target="_blank" rel="noopener noreferer" href="https://www.radio.bialystok.pl/wiadomosci/index/id/195758">Artykuł w Radiu Białystok</a>`
            ]
        }
    ]

  return (
    <>
        <section className="log">
            <h2>Lista aktualizacji 💾</h2>
            <div className="log__grid">
            {updates.map((update, i) => <ChangelogPosition key={i} update={update}/>)}</div>
        </section>
    </>
  );
}
